.codeview {
  height: 87%;
  width: 75%;
  position: absolute;
  bottom: 0;
  padding: 5px;
  padding-top: 0;
}

#code-editor {
  width: 100%;
  height: 100%;
  border-radius: 9px;
  padding: 16px;
  overflow-y: auto;
}
