.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  position: absolute;
  padding-left: 40px;
  width: 100%;
  height: 13%;
  border-bottom: 2px solid #373A40;
}

#blocklyDiv {
  height: 87%;
  width: 75%;
  position: absolute;
  bottom: 0;
}

.logo-wrapper {
  text-align: center;
  margin-top: 10px;
}

.logo-link {
  font-size: 14px;
}

.about-grat {
  font-size: 14px;
  width: 200px;
  margin-top: 10px;
  margin-left: 60px;
  line-height: 18px;
  font-weight: bolder;
}

.link {
  color: #2b69e8;
  text-decoration: none;
}

a:hover {
  color: #64a8f8;
}


.menu-buttons {
  margin-top: 10px;
  position: absolute;
  right: 40px;
}

.blocklyToolboxDiv {
  background-color: #1A1B1E;
  width: 15%;
}

.blocklyToolboxContents {
  padding: 20px;
  padding-top: 60px;
  margin: auto;
}

svg.blocklyMainWorkspaceScrollbar {
  display: none;
}

svg.blocklyFlyoutScrollbar {
  display: none;
}

.blocklyFlyoutBackground {
  fill: #25262B !important;
}

rect.blocklyMainBackground {
  fill: #2C2E33 !important;
  width: 85%;
  transform: translateX(15%);
}

#blockTitle {
  position: absolute;
  top: 10%;
  left: 7%;
  transform: translate(-50%, 10px);
  z-index: 500;
}

#workspaceTitle {
  position: absolute;
  top: 10%;
  left: 45%;
  transform: translate(-50%, 10px);
  z-index: 500;
}

#Sidebar {
  height: 87%;
  width: 25%;
  position: absolute;
  bottom: 0;
}

#unity-canvas-1 {
  width: 100%;
  height: 40%;
}

.hidden {
  display: none;
}

.project-view {
  position: absolute;
  bottom: 0;
  height: calc(60% - 7px);
  padding-right: 12px;
  padding-bottom: 40px;
  word-wrap: break-word;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;  
  -ms-overflow-style: none;
}

.project-view::-webkit-scrollbar {
  width: 0 !important
}

.fundrasier {
  background: #2b69e8;
  border-radius: 12px;
  padding: 0 12px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
